import {
  GET_DISTRICTS_SUCCESS,
  GET_SUBDISTRICTS_SUCCESS,
  RESET_DISTRICTS,
  RESET_SUBDISTRICTS,
} from "./type";

const initialState = {
  districts: [],
  subdistricts: [],
};

const regionalReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DISTRICTS_SUCCESS:
      return {
        ...state,
        districts: action.payload,
      };
    case GET_SUBDISTRICTS_SUCCESS:
      return {
        ...state,
        subdistricts: action.payload,
      };
    case RESET_DISTRICTS:
      return {
        ...state,
        districts: [],
      };
    case RESET_SUBDISTRICTS:
      return {
        ...state,
        subdistricts: [],
      };
    default:
      return state;
  }
};

export default regionalReducer;
