import { request } from "../../../config";
import {
  GET_DISTRICTS_SUCCESS,
  GET_SUBDISTRICTS_SUCCESS,
  PROVINCE_ID_SUCCESS,
  RESET_DISTRICTS,
  RESET_PROVINCE,
  RESET_SUBDISTRICTS,
} from "./type";

export const provinceID = (provinceId) => async (dispatch) => {
  dispatch({
    type: PROVINCE_ID_SUCCESS,
    payload: provinceId,
  });
};

export const resetProvinceId = () => async (dispatch) => {
  dispatch({
    type: RESET_PROVINCE,
  });
};

export const districtByProvince = (provinceId) => async (dispatch) => {
  try {
    const response = await request.get(`/provinces/${provinceId}/districts`);

    if (response.data.status === 200) {
      dispatch({
        type: GET_DISTRICTS_SUCCESS,
        payload: response.data.data,
      });
    } else {
      dispatch({
        type: RESET_DISTRICTS,
        payload: [],
      });
    }
  } catch (error) {
    console.error("Error fetching districts:", error);
  }
};

export const resetDistricts = () => async (dispatch) => {
  dispatch({
    type: RESET_DISTRICTS,
  });
};

export const subdistrictByDistrict = (districtId) => async (dispatch) => {
  try {
    const response = await request.get(`/districts/${districtId}/subdistricts`);

    if (response.data.status === 200) {
      dispatch({
        type: GET_SUBDISTRICTS_SUCCESS,
        payload: response.data.data,
      });
    } else {
      dispatch({
        type: RESET_SUBDISTRICTS,
      });
    }
  } catch (error) {
    console.error("Error fetching districts:", error);
  }
};

export const resetSubdistricts = () => async (dispatch) => {
  dispatch({
    type: RESET_SUBDISTRICTS,
  });
};
