export const GET_CUSTOMER_BY_ID_SUCCESS = 'GET_CUSTOMER_BY_ID_SUCCESS';
export const UPDATE_NAME_CUSTOMER = 'UPDATE_NAME_CUSTOMER';
export const UPDATE_EMAIL_CUSTOMER = 'UPDATE_EMAIL_CUSTOMER';
export const UPDATE_NUMBER_CUSTOMER = 'UPDATE_NUMBER_CUSTOMER';
export const UPDATE_PREFIX_PHONE = 'UPDATE_PREFIX_PHONE';
export const UPDATE_POSTAL_CODE = 'UPDATE_POSTAL_CODE';
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';

export const UPDATE_CUSTOMER_PROVINCE_ID = 'UPDATE_CUSTOMER_PROVINCE_ID';
export const UPDATE_CUSTOMER_DISTRICT_ID = 'UPDATE_CUSTOMER_DISTRICT_ID';
export const UPDATE_CUSTOMER_SUBDISTRICT_ID = 'UPDATE_CUSTOMER_SUBDISTRICT_ID';

export const RESET_REGIONAL_CUSTOMER = 'RESET_REGIONAL_CUSTOMER';
export const RESET_CUSTOMER = 'RESET_CUSTOMER';

export const SELECTED_ORDER_SOURCE = 'SELECTED_ORDER_SOURCE';
export const SELECTED_PLATFORM = 'SELECTED_PLATFORM';
export const SELECTED_ORDER_DATE = 'SELECTED_ORDER_DATE';
export const CREATE_INVOICE = 'CREATE_INVOICE';
export const REQUEST_TAX_INVOICE = 'REQUEST_TAX_INVOICE';
export const UPLOAD_NPWP = 'UPLOAD_NPWP';

export const CUSTOMER_LOADING = 'CUSTOMER_LOADING';
export const CUSTOMER_LOADED = 'CUSTOMER_LOADED';

// export const RESET_CUSTOMER = "RESET_CUSTOMER";

// export const GET_DISTRICTS_SUCCESS = "GET_DISTRICTS_SUCCESS";
// export const RESET_DISTRICTS = "RESET_DISTRICTS";

// export const GET_SUBDISTRICTS_SUCCESS = "GET_SUBDISTRICTS_SUCCESS";
// export const RESET_SUBDISTRICTS = "RESET_SUBDISTRICTS";
