import { PAYMENT_EVIDENCE } from "./type";

const initialState = {
  file: {},
  url: null,
  evidence: [],
};

const uploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case PAYMENT_EVIDENCE:
      return { ...state, file: action.payload.file };
    default:
      return state;
  }
};

export default uploadReducer;
