// NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound.css'; // Impor file CSS untuk gaya

const NotFound = () => {
  return (
    <div className="notfound-container">
      <div className="notfound-content">
        <img
          src="https://via.placeholder.com/400x300.png?text=404+Not+Found"
          alt="404 Not Found"
          className="notfound-image"
        />
        <h1>404 - Halaman Tidak Ditemukan</h1>
        <p>Oops. </p>
        <Link to="/" className="notfound-link">
          Kembali ke Beranda
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
