import { phoneCodes } from '../../../helpers/phoneCode';
import {
  CREATE_INVOICE,
  CUSTOMER_LOADED,
  CUSTOMER_LOADING,
  GET_CUSTOMER_BY_ID_SUCCESS,
  REQUEST_TAX_INVOICE,
  RESET_CUSTOMER,
  RESET_REGIONAL_CUSTOMER,
  SELECTED_ORDER_DATE,
  SELECTED_ORDER_SOURCE,
  SELECTED_PLATFORM,
  UPDATE_ADDRESS,
  UPDATE_CUSTOMER_DISTRICT_ID,
  UPDATE_CUSTOMER_PROVINCE_ID,
  UPDATE_CUSTOMER_SUBDISTRICT_ID,
  UPDATE_EMAIL_CUSTOMER,
  UPDATE_NAME_CUSTOMER,
  UPDATE_NUMBER_CUSTOMER,
  UPDATE_POSTAL_CODE,
  UPDATE_PREFIX_PHONE,
  UPLOAD_NPWP,
} from './type';

const initialState = {
  isLoading: false,
  id: null,
  order_date: '',
  npwp: '',
  request_tax_invoice: null,
  name: '',
  phone: '',
  email: '',
  address: '',
  postal_code: '',
  provinceId: null,
  districtId: null,
  subdistrictId: null,
  shipment: {
    name: '',
    prefix: '+62',
    phone: '',
    email: '',
    province_name: '',
    district_name: '',
    subdistrict_name: '',
  },
};

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case CUSTOMER_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case CUSTOMER_LOADED:
      return {
        ...state,
        isLoading: false,
      };

    case GET_CUSTOMER_BY_ID_SUCCESS:
      const phoneNumber = action.payload.phone;
      // Mencari kode prefix yang sesuai dalam data JSON
      const matchedPrefix = phoneCodes.find((code) => phoneNumber.startsWith(code.dial_code));
      let prefix = '+62';
      let phone = '';

      if (matchedPrefix) {
        prefix = matchedPrefix.dial_code; // Set prefix
        phone = phoneNumber.replace(matchedPrefix.dial_code, '');
      } else {
        phone = phoneNumber;
      }

      return {
        ...state,
        ...action.payload,
        shipment: {
          name: action.payload.name,
          prefix,
          phone,
          email: action.payload.email,
          province_name: action.payload.province.name,
          district_name: action.payload.district.name,
          subdistrict_name: action.payload.subdistrict.name,
        },
        provinceId: action.payload.province.id,
        districtId: action.payload.district.id,
        subdistrictId: action.payload.subdistrict.id,
      };
    case UPDATE_NAME_CUSTOMER:
      return {
        ...state,
        shipment: {
          ...state.shipment,
          name: action.payload,
        },
      };
    case UPDATE_NUMBER_CUSTOMER:
      return {
        ...state,
        shipment: {
          ...state.shipment,
          phone: action.payload,
        },
      };
    case UPDATE_PREFIX_PHONE:
      return {
        ...state,
        shipment: {
          ...state.shipment,
          prefix: action.payload,
        },
      };
    case UPDATE_EMAIL_CUSTOMER:
      return {
        ...state,
        shipment: {
          ...state.shipment,
          email: action.payload,
        },
      };
    case UPDATE_CUSTOMER_PROVINCE_ID:
      return {
        ...state,
        provinceId: action.payload.value,
        shipment: {
          ...state.shipment,
          province_name: action.payload.name,
        },
      };
    case UPDATE_CUSTOMER_DISTRICT_ID:
      return {
        ...state,
        districtId: action.payload.value,
        shipment: {
          ...state.shipment,
          district_name: action.payload.name,
        },
      };
    case UPDATE_CUSTOMER_SUBDISTRICT_ID:
      return {
        ...state,
        subdistrictId: action.payload.value,
        shipment: {
          ...state.shipment,
          subdistrict_name: action.payload.name,
        },
      };
    case UPDATE_ADDRESS:
      return {
        ...state,
        address: action.payload,
      };
    case UPDATE_POSTAL_CODE:
      return {
        ...state,
        postal_code: action.payload,
      };
    case SELECTED_ORDER_SOURCE:
      return {
        ...state,
        order_source: action.payload,
      };
    case SELECTED_PLATFORM:
      return {
        ...state,
        platform: action.payload.platform,
        invoice_required: action.payload.invoice_required || false,
      };
    case CREATE_INVOICE:
      return {
        ...state,
        invoice_marketplace: action.payload,
      };
    case REQUEST_TAX_INVOICE:
      return {
        ...state,
        request_tax_invoice: action.payload,
      };
    case UPLOAD_NPWP:
      return {
        ...state,
        npwp: action.payload,
      };
    case SELECTED_ORDER_DATE:
      return {
        ...state,
        order_date: action.payload,
      };
    case RESET_REGIONAL_CUSTOMER:
      return {
        ...state,
        provinceId: null,
        districtId: null,
        subdistrictId: null,
      };
    case RESET_CUSTOMER:
      return initialState;
    default:
      return state;
  }
};

export default customerReducer;
