/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useReactToPrint } from 'react-to-print';
import OrderServices from '../../../../services/OrderServices';
import { defaultState } from './defaultState';

import logo from '../../../../assets/img/coversuper_icon.png';
import graphicIcon from '../../../../assets/img/graphic.png';
import alertIcon from '../../../../assets/img/icons/alert-icon.png';
import kerangka from '../../../../assets/img/kerangka.png';
import logoBarcode from '../../../../assets/img/label_coversuper.png';
import logoForce from '../../../../assets/img/logo-force-new.png';

import { Card, Col, QRCode, Row, Tag } from 'antd';
import moment from 'moment';
import {
  CATEGORY_COVER_MOBIL,
  STATUS_DESAIN_SEDANG_DIBUAT,
  UNIT_CHAMP,
  UNIT_EXCELLENT,
  UNIT_FORCE,
  UNIT_GREAT,
} from '../../../../constants';

const PrintLabel = () => {
  const { id } = useParams();
  const labelRef = useRef();
  const [data, setData] = useState(defaultState);
  const [order, setOrder] = useState(defaultState.order);
  const [shipment, setShipment] = useState(defaultState.order.shipment);
  let withSablon = false;
  if (data?.addons?.length > 0) {
    withSablon =
      data?.addons?.filter((addon) => (addon?.name || '').toLowerCase().includes('sablon')).length >
      0;
  }

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const response = await OrderServices.getDataByItem(id);

    const resData = response.data;
    setData(resData);
    setOrder(resData.order);
    setShipment(resData.order.shipment);

    getCanvas();
  };

  const getCanvas = async () => {
    setTimeout(() => {
      handlePrint();
    }, 2000);
  };

  const renderText = (item) => {
    let vehicle = item.vehicle.vehicle_model.name;
    let manufacturer = item.vehicle.vehicle_model.manufacturer.name;
    let productName = item.product.name;
    let productRow = item.variant.row;
    let year = item.year;
    let colorName = item.color.name;

    let arrProduct = [productName, productRow, colorName, ':', manufacturer, vehicle, year];

    let isReadyStock = item.product.is_ready_stock;
    if (isReadyStock) {
      arrProduct.push('(Size ' + item.variant.size + ')');
    }

    return arrProduct.map((e) => e).join(' ');
  };

  const renderDetailPesanan = () => {
    let items = order?.items;

    return items.map((item, key) => {
      let hasSablon = false;
      if (item?.addons?.length > 0) {
        hasSablon =
          item?.addons?.filter((addon) => (addon.name || '').toLowerCase().includes('sablon'))
            .length > 0;
      }
      return (
        <tr key={key} className={hasSablon ? 'bg-yellow-300' : ''}>
          <td className="p-2 border border-gray-300 w-2/5">
            <p className="text-xs">{renderText(item)}</p>
          </td>
          <td className="text-center text-xs bg-neutral-200 border border-gray-300">{item.qty}</td>
          <td className="p-2 text-xs border border-gray-300">
            {!item.product.is_ready_stock && item.design ? `Desain: ${item.design.name}` : ''}
            <br />
            {item.note}
          </td>
        </tr>
      );
    });
  };

  const handlePrint = useReactToPrint({
    content: () => labelRef.current,
    documentTitle: 'Print Label',
  });

  const classNameByUnit = (unit) => {
    switch (unit) {
      case UNIT_GREAT:
        return 'bg-red-600';
      case UNIT_EXCELLENT:
        return 'bg-black';
      case UNIT_FORCE:
        return 'bg-[#1b4298]';
      case UNIT_CHAMP:
        return 'bg-[#02ccfe]';
      default:
        return 'bg-[#90ff90]';
    }
  };

  return (
    <div className="p-5" ref={labelRef}>
      <div className="flex justify-between">
        <img
          className="text-left"
          width={200}
          height={200}
          src={data?.order?.unit?.id === UNIT_FORCE ? logoForce : logo}
          alt=""
        />

        <div className="text-right font-medium">
          {moment().format('DD-MM-YYYY HH:mm')} <br />
          <span style={{ fontWeight: 'bolder' }}>
            {[
              shipment?.courier_name.toUpperCase(),
              shipment?.courier_service,
              shipment?.tracking_number,
            ]
              .filter((e) => e)
              .join(' - ')}{' '}
            {/* |{' '}
            {parseInt(order.shipping_cost).toLocaleString('id', {
              style: 'currency',
              currency: 'IDR',
              minimumFractionDigits: 0,
              // maximumFractionDigits: 0
            })} */}
          </span>
          <br />
          {`${data?.order.order_number} ${data?.order.dealmaker.name}`}
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 my-10">
        <li className="text-sm list-none">
          <strong>Kepada</strong>
          <br />
          <strong>{shipment.name}</strong>
          <br />
          {shipment.address}, {shipment.subdistrict.name}, {shipment.district.type}{' '}
          {shipment.district.name}, {shipment.province.name}, {shipment.postal_code}
          <br />
          HP : {shipment.phone}
        </li>

        <li className="text-sm list-none">
          <strong>Pengirim</strong> <br />
          <strong>{shipment.shipper.name}</strong> <br />
          {shipment.shipper.address}, {shipment.shipper.subdistrict.name},{' '}
          {shipment.shipper.district.type} {shipment.shipper.district.name},{' '}
          {shipment.shipper.province.name}
          <br />
          HP : {shipment.shipper.phone}
        </li>
      </div>
      <table id="table-order-items" className="border-collapse border antialiased w-full">
        <thead>
          <tr className={`${classNameByUnit(data.order.unit?.id)} text-white`}>
            <th className="text-left border border-gray-300 p-2">Pesanan</th>
            <th className="text-center border border-gray-300">Qty</th>
            <th className="text-left border border-gray-300 px-2">Catatan</th>
          </tr>
        </thead>

        <tbody>
          {renderDetailPesanan()}
          <tr>
            <td className="p-2">
              <strong>Jumlah Pesanan</strong>
            </td>
            <td className="text-center text-red-500 border border-gray-300 bg-neutral-200">
              <strong>{order.items.map((a) => a.qty).reduce((a, b) => a + b)}</strong>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <br />
      <Row gutter={16}>
        <Col
          xs={24}
          md={18}
          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
        >
          <div>
            <>
              Status Pembayaran :{' '}
              {order?.payment_status.name === 'Lunas' ? (
                <Tag color="#87d068">{order?.payment_status.name}</Tag>
              ) : (
                <Tag color="#f50">{order?.payment_status.name}</Tag>
              )}
              <br />
              Keterangan / Bonus : {order?.bonus?.join(', ') || ''} {order?.description || ''}
            </>
          </div>
          <Row gutter={16}>
            <Col xs={3}>
              <p>
                <img src={alertIcon} alt="" style={{ maxHeight: '50px' }} />
              </p>
            </Col>
            <Col xs={21}>
              <p
                style={{
                  whiteSpace: 'nowrap',
                  fontWeight: 'bold',
                  color: '#E62131',
                  fontSize: '0.8rem',
                }}
              >
                PENTING!!!
                <br />
                HARAP VIDEOKAN SAAT MEMBUKA PAKET UNTUK KLAIM BARANG JIKA ADA KEKURANGAN.
                <br /> TERIMA KASIH.
              </p>
            </Col>
          </Row>
        </Col>
        <Col
          xs={24}
          md={6}
          style={{
            zIndex: -1,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
          }}
        >
          <img src={graphicIcon} width="120" alt="clip" />
        </Col>
      </Row>

      <hr style={{ marginTop: 0 }} />

      <div className="grid grid-cols-2 gap-4 mt-5">
        <div>
          <table className="border-collapse w-full list-none">
            <tbody>
              <tr className="bg-neutral-200">
                <td className="p-1 border border-gray-300 w-1/5">
                  <li className="text-sm">Model Cover</li>
                </td>
                <td className="p-1 border border-gray-300 w-3/5">{data?.cover_model}</td>
              </tr>

              <tr>
                <td className="p-1 border border-gray-300 w-1/5">
                  <li className="text-sm">Warna/Produk</li>
                </td>
                <td className="p-1 text-sm border border-gray-300 w-3/5">
                  {data?.color?.name}{' '}
                  {data.color_variant_1 && data.color_variant_2
                    ? `(${data.material.name} - ${data.fiber.name})`
                    : ''}{' '}
                  / <span className="font-bold	">{data?.product?.name}</span>
                </td>
              </tr>
              <tr className="bg-neutral-200">
                <td className="p-1 border border-gray-300 w-1/5">
                  <li className="text-sm">Tipe/Merk</li>
                </td>
                <td className="p-1 font-bold border border-gray-300 w-3/5">
                  {data?.vehicle.vehicle_model?.manufacturer?.name}{' '}
                  {data?.vehicle.vehicle_model?.name}{' '}
                  {data?.transmission_type ? `Transmisi ${data?.transmission_type}` : ''}{' '}
                  {data?.year}
                </td>
              </tr>

              <tr>
                <td className="p-1 border border-gray-300 w-1/5">
                  <li className="text-sm">Flow ID Produksi</li>
                </td>
                <td className="p-1 text-sm border border-gray-300 w-3/5">{id}</td>
              </tr>

              <tr className="bg-neutral-200">
                <td className="p-1 border border-gray-300 w-1/5">
                  <li className="text-sm">Nomor Order</li>
                </td>
                <td className="p-1 text-sm border border-gray-300 w-3/5">
                  {data?.order?.order_number}
                </td>
              </tr>

              <tr>
                <td className="p-1 border border-gray-300 w-1/5">
                  <li className="text-sm">Dealmaker</li>
                </td>
                <td className="p-1 text-sm border border-gray-300 w-3/5">
                  {data?.order?.dealmaker?.name}
                </td>
              </tr>

              <tr className="bg-neutral-200">
                <td className="p-1 border border-gray-300 w-1/5">
                  <li className="text-sm">Pemesan</li>
                </td>
                <td className="p-1 text-sm border border-gray-300 w-3/5">
                  {data?.order?.customer?.name}
                </td>
              </tr>

              <tr>
                <td className="p-1 border border-gray-300 w-1/5">
                  <li className="text-sm">Penerima</li>
                </td>
                <td className="p-1 text-sm border border-gray-300 w-3/5">
                  {data?.order?.shipment?.name}
                </td>
              </tr>
              {data?.product?.is_ready_stock === 0 && (
                <>
                  <tr className="bg-neutral-200">
                    <td className="p-1 border border-gray-300 w-1/5">
                      <li className="text-sm">Panjang</li>
                    </td>
                    <td className="p-1 text-sm border border-gray-300 w-3/5">
                      {data?.length ? `${data.length} cm` : '-'}
                      {data?.additional_length > 0 &&
                        `+ ${data?.additional_length} cm =  ${
                          Number(data?.length) + Number(data?.additional_length)
                        } cm`}
                    </td>
                  </tr>

                  <tr>
                    <td className="p-1 border border-gray-300 w-1/5">
                      <li className="text-sm">Lebar</li>
                    </td>
                    <td className="p-1 text-sm border border-gray-300 w-3/5">
                      {data?.width ? `${data.width} cm` : '-'}
                    </td>
                  </tr>

                  <tr className="bg-neutral-200">
                    <td className="p-1 border border-gray-300 w-1/5">
                      <li className="text-sm">Tinggi</li>
                    </td>
                    <td className="p-1 text-sm border border-gray-300 w-3/5">
                      {data?.height ? `${data.height} cm` : '-'}
                    </td>
                  </tr>

                  <tr>
                    <td className="p-1 border border-gray-300 w-1/5">
                      <li className="text-sm">Tipe Cover</li>
                    </td>
                    <td className="p-1 text-sm border border-gray-300 w-3/5">
                      {data?.design?.name}
                    </td>
                  </tr>

                  <tr className="bg-neutral-200">
                    <td className="p-1 border border-gray-300 w-1/5">
                      <li className="text-sm">Warna List 1</li>
                    </td>
                    <td className="p-1 text-sm border border-gray-300 w-3/5">
                      {data?.color_variant_1?.name}{' '}
                      {data.color_variant_1 && data.color_variant_2
                        ? `(${data.material_variant_1?.name} - ${data.fiber_variant_1?.name})`
                        : ''}
                    </td>
                  </tr>

                  <tr className="bg-neutral-200">
                    <td className="p-1 border border-gray-300 w-1/5">
                      <li className="text-sm">Warna List 2</li>
                    </td>
                    <td className="p-1 text-sm border border-gray-300 w-3/5">
                      {data?.color_variant_2?.name}{' '}
                      {data.color_variant_1 && data.color_variant_2
                        ? `(${data.material_variant_2?.name} - ${data.fiber_variant_2?.name})`
                        : ''}
                    </td>
                  </tr>

                  <tr>
                    <td className="p-1 border border-gray-300 w-1/5">
                      <li className="text-sm">Logo</li>
                    </td>
                    <td className="p-1 text-sm border border-gray-300 w-3/5">{data?.logo}</td>
                  </tr>

                  <tr className="bg-neutral-200">
                    <td className="p-1 border border-gray-300 w-1/5">
                      <li className="text-sm">APAR</li>
                    </td>
                    <td className="p-1 text-sm border border-gray-300 w-3/5">
                      {data?.fire_extinguisher}
                    </td>
                  </tr>
                  <tr>
                    <td className="p-1 border border-gray-300 w-1/5">
                      <li className="text-sm">Lubang Pengunci</li>
                    </td>
                    <td className="p-1 text-sm border border-gray-300 w-3/5">{data?.lock_hole}</td>
                  </tr>

                  <tr className={withSablon ? 'bg-yellow-300' : 'bg-neutral-200'}>
                    <td className="p-1 border border-gray-400 w-1/5">
                      <li className="text-sm">Tambahan</li>
                    </td>
                    <td className="p-1 text-sm border border-gray-300 w-3/5">
                      {data?.addons.map((addon) => addon.name).join(', ')}
                    </td>
                  </tr>
                </>
              )}

              <tr>
                <td className="p-1 border border-gray-300 w-1/5">
                  <li className="text-base font-semibold">Catatan</li>
                </td>
                <td className="p-1 text-base font-semibold border border-gray-300 w-3/5">
                  {data?.note}
                </td>
              </tr>
            </tbody>
          </table>

          <br />

          <li className="text-sm list-none">
            Status Pembayaran :{' '}
            {order?.payment_status.name === 'Lunas' ? (
              <Tag color="#87d068">{order?.payment_status.name}</Tag>
            ) : (
              <Tag color="#f50">{order?.payment_status.name}</Tag>
            )}
          </li>

          <br />
          {data?.product?.is_ready_stock === 0 && (
            <table className="border-collapse w-full list-none">
              <tbody>
                <tr className="bg-neutral-200">
                  <td className="p-1 border border-gray-300 w-1/5">
                    <li className="text-sm">Tinggi Cover</li>
                  </td>
                  <td className="p-1 border border-gray-300 w-1/5">
                    <li className="text-sm">Catatan Resep</li>
                  </td>
                </tr>

                <tr>
                  <td className="p-1 border border-gray-300 w-3/5">{data?.cover_height} cm</td>
                  <td className="p-1 text-sm border border-gray-300 w-3/5">
                    {(() => {
                      const historySik = data.histories.filter(
                        (history) => history.status.id === STATUS_DESAIN_SEDANG_DIBUAT
                      );

                      const history = historySik[historySik.length - 1];
                      return history
                        ? history.description
                          ? history.description.split('\n').length > 0
                            ? history.description
                                .split('\n')
                                .map((item, i) => <p key={i}>{item}</p>)
                            : history.description
                          : ''
                        : '';
                    })()}
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>

        <div>
          <div className="grid grid-cols-2 gap-4 my-10">
            <div className="text-left">
              <h2 className="text-2xl font-bold">{data.order.unit?.name}</h2>
            </div>

            <div className="text-right">
              <h2 className="text-right text-large font-medium">Estimasi:</h2>
              {data && data.estimation && (
                <>
                  <h2 className="text-right text-large font-medium">
                    {moment(data.estimation).format('dddd, DD MMM YYYY')}
                  </h2>
                </>
              )}
            </div>
          </div>

          <div className="flex justify-center items-center my-5">
            <QRCode value={id} type="svg" icon={logoBarcode} iconSize={25} size={150} />
            &nbsp;
            <li className="text-sm font-semibold text-red-500 list-none">{id}</li>
          </div>
          <br />

          {[CATEGORY_COVER_MOBIL].includes(data?.product?.category?.id) && (
            <div className="flex justify-center items-center px-4">
              <img src={kerangka} alt="kerangka" className="w-full h-full" />
            </div>
          )}

          {data?.order?.invoice_marketplace && (
            <div className="flex justify-center items-center mt-8">
              <Card className="border-rose-500">
                <h2 className="text-rose-500">
                  Order Ini dari Marketplace, mohon print invoicenya.
                </h2>
              </Card>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PrintLabel;
