import {
  ADDONS_IMAGE,
  ADD_PRODUCT,
  DELETE_PRODUCT,
  REMOVE_ADDONS,
  REMOVE_ADDONS_IMAGE,
  RESET_PRODUCT,
  UPDATE_ADDONS,
  UPDATE_ADDONS_PRICE,
  UPDATE_NOTE_PRODUCT,
  UPDATE_PRICE_ADJUSTMENT,
  UPDATE_PRICE_DESIGN,
  UPDATE_PRODUCT,
  UPDATE_QTY,
  UPDATE_SUBTOTAL,
  UPDATE_TOTAL_WEIGHT,
} from './type';

export const addProduct = (data) => (dispatch) => {
  dispatch({
    type: ADD_PRODUCT,
    payload: data,
  });

  dispatch({ type: UPDATE_ADDONS_PRICE });

  dispatch({ type: UPDATE_SUBTOTAL });

  dispatch({ type: UPDATE_TOTAL_WEIGHT });
};

export const updateProduct = () => (dispatch) => {
  dispatch({ type: UPDATE_PRODUCT });
};

export const countAddonsPrice = () => (dispatch) => {
  dispatch({ type: UPDATE_ADDONS_PRICE });
};

export const countSubtotal = () => (dispatch) => {
  dispatch({ type: UPDATE_ADDONS_PRICE });
  dispatch({ type: UPDATE_SUBTOTAL });
};

export const updatePriceAdjustment = (key, value) => (dispatch) => {
  dispatch({
    type: UPDATE_PRICE_ADJUSTMENT,
    payload: {
      key: key,
      value: value,
    },
  });

  dispatch({ type: UPDATE_SUBTOTAL });
};

export const updatePriceDesign = (productKey, value) => (dispatch) => {
  dispatch({
    type: UPDATE_PRICE_DESIGN,
    payload: {
      key: productKey,
      price_design: value,
    },
  });

  dispatch({ type: UPDATE_SUBTOTAL });
};

export const updateProductNote = (idx, value) => (dispatch) => {
  dispatch({
    type: UPDATE_NOTE_PRODUCT,
    payload: {
      idx: idx,
      value: value,
    },
  });
};

export const updateProductAddons = (key, value) => (dispatch) => {
  dispatch({
    type: UPDATE_ADDONS,
    payload: {
      key: key,
      value: value,
    },
  });
};

export const removeProductAddons = (key, id) => (dispatch) => {
  dispatch({
    type: REMOVE_ADDONS,
    payload: {
      key: key,
      id: id,
    },
  });
};

export const updateProductImageAddons = (key, files) => (dispatch) => {
  dispatch({
    type: ADDONS_IMAGE,
    payload: {
      key: key,
      data: files,
    },
  });
};

export const removeImageAddons = (key, url) => (dispatch) => {
  dispatch({
    type: REMOVE_ADDONS_IMAGE,
    payload: {
      key: key,
      url: url,
    },
  });
};

export const updateQty = (key, value) => (dispatch) => {
  dispatch({
    type: UPDATE_QTY,
    payload: {
      key: key,
      qty: value,
    },
  });

  dispatch({ type: UPDATE_TOTAL_WEIGHT });

  dispatch({ type: UPDATE_SUBTOTAL });
};

export const updateTotalWeight = (idx, value) => (dispatch) => {
  dispatch({ type: UPDATE_TOTAL_WEIGHT });
};

export const deleteProduct = (key) => (dispatch) => {
  dispatch({
    type: DELETE_PRODUCT,
    payload: key,
  });

  dispatch({ type: UPDATE_PRODUCT });

  dispatch({ type: UPDATE_ADDONS_PRICE });

  dispatch({ type: UPDATE_TOTAL_WEIGHT });

  dispatch({ type: UPDATE_SUBTOTAL });
};

export const resetProduct = (key) => (dispatch) => {
  dispatch({
    type: RESET_PRODUCT,
  });
};
