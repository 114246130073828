export const GET_COURIER_SUCCESS = 'GET_COURIER_SUCCESS';

export const GET_SHIPPING_COST_SUCCESS = 'GET_SHIPPING_COST_SUCCESS';
export const RESET_DATA_SHIPPING_COST = 'RESET_DATA_SHIPPING_COST';

export const DATA_COURIER = 'DATA_COURIER';
export const TRACKING_NUMBER = 'TRACKING_NUMBER';
export const COURIER_SERVICE = 'COURIER_SERVICE';
export const COURIER_COST = 'COURIER_COST';
export const RESET_COST_SERVICE = 'RESET_COST_SERVICE';
export const RESET_COURIER = 'RESET_COURIER';
export const UPDATE_COURIER = 'UPDATE_COURIER';

export const ORIGIN_ID = 'ORIGIN_ID';
