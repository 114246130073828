import OrderServices from '../../../services/OrderServices'
import {
  ADDITIONAL_COST,
  ADDITIONAL_COST_NOTE,
  BONUS,
  DESCRIPTION,
  DISCOUNT,
  GET_BONUS_OPTION_SUCCESS,
  RESET_SUMMARY,
} from './type'

export const updateDiscount = (value) => async (dispatch) => {
  dispatch({
    type: DISCOUNT,
    payload: parseInt(value) || 0,
  })
}

export const updateAdditionalCost = (value) => async (dispatch) => {
  dispatch({
    type: ADDITIONAL_COST,
    payload: parseInt(value) || 0,
  })
}

export const updateAdditionalCostNote = (value) => async (dispatch) => {
  dispatch({
    type: ADDITIONAL_COST_NOTE,
    payload: value,
  })
}

export const getDataBonusOptions = () => async (dispatch) => {
  try {
    const response = await OrderServices.getBonuses()

    if (response.status === 200) {
      dispatch({
        type: GET_BONUS_OPTION_SUCCESS,
        payload: response.data.map((bonus) => ({
          value: bonus.name,
          label: bonus.name,
          style: {
            fontSize: '12px',
          },
        })),
      })
    }
  } catch (error) {
    console.log(error)
  }
}

export const updateBonus = (value) => async (dispatch) => {
  dispatch({
    type: BONUS,
    payload: value,
  })
}

export const updateDescription = (value) => async (dispatch) => {
  dispatch({
    type: DESCRIPTION,
    payload: value,
  })
}

export const resetDataSummary = () => async (dispatch) => {
  dispatch({
    type: RESET_SUMMARY,
  })
}
