import { combineReducers } from 'redux';
import authReducer from './Auth/reducer';
import courierReducer from './FormOrder/Couriers/reducer';
import customerReducer from './FormOrder/Customer/reducer';
import paymentReducer from './FormOrder/Payment/reducer';
import productReducer from './FormOrder/Products/reducer';
import regionalReducer from './FormOrder/Regional/reducer';
import summaryReducer from './FormOrder/Summary/reducer';
import uploadReducer from './FormOrder/Upload/reducer';
import socketReducer from './Socket/reducer';

export default combineReducers({
  auth: authReducer,
  customer: customerReducer,
  products: productReducer,
  regional: regionalReducer,
  couriers: courierReducer,
  summary: summaryReducer,
  payment: paymentReducer,
  upload: uploadReducer,
  socket: socketReducer,
});
