import { request } from '../../config';
import {
  AUTH_ERROR,
  CLEAR_ERRORS,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  USER_LOADED,
  USER_LOADING,
} from './type';

import UserService from '../../services/UserServices';
import { returnErrors } from './errorAction';

// check token and load user
export const loadUser = () => (dispatch) => {
  // User Loading
  dispatch({ type: USER_LOADING });

  UserService.getProfile()
    .then((res) => {
      dispatch({ type: USER_LOADED, payload: res.data });
    })
    .catch((err) => {
      // get error message
      dispatch(returnErrors(err.response.data, err.response.status));
      //remove token
      dispatch({ type: AUTH_ERROR });
    });
};

// Login User
export const login = (email, password) => (dispatch) => {
  dispatch({ type: USER_LOADING });

  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({ email, password });

  request
    .post(`/login`, body, config)
    .then((res) => {
      if (res) {
        dispatch({ type: LOGIN_SUCCESS, payload: res.data });
        dispatch({ type: CLEAR_ERRORS });
      } else {
        throw new Error('Gagal Login');
      }
    })
    .catch((err) => {
      // console.log('LOGIN ERR', err)
      dispatch(returnErrors(err.message, 400, 'LOGIN_FAIL'));
      dispatch({ type: LOGIN_FAIL });
    });
};

// Logout User
export const logout = () => (dispatch, getState) => {
  return dispatch({ type: LOGOUT_SUCCESS });
};
