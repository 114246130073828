import { Ability } from "@casl/ability";
import store from "../store";

function subjectName(item) {
  if (!item || typeof item === "string") {
    return item;
  }

  return item.__type;
}

const ability = new Ability([], { subjectName });

ability.update(store.getState().auth.permissions);

export default ability;
