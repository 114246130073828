import { request } from '../config';

// versi function
function CustomerService() {
  const addCustomer = async (data) => {
    const response = await request.post(`/customers`, data);

    return response.data;
  };

  const updateCustomer = async (customer_id, data) => {
    const response = await request.put(`/customers/${customer_id}`, data);

    return response.data;
  };

  const findCustomerById = async (customer_id) => {
    const response = await request.get(`/customers/${customer_id}`);

    return response.data;
  };

  const searchCustomer = async (params) => {
    const response = await request.get(`/customers/pagination`, { params });

    return response.data;
  };

  const checkExisting = async (filter) => {
    const response = await request.post(`/customers/check`, filter);

    return response.data;
  };

  const getCustomerIdOrders = async (id) => {
    const response = await request.get(`/customers/${id}/orders`);

    return response.data;
  };

  const customerTypeList = async () => {
    const response = await request.get(`/customers/types`);

    return response.data;
  };

  return {
    addCustomer,
    updateCustomer,
    findCustomerById,
    searchCustomer,
    getCustomerIdOrders,
    checkExisting,
    customerTypeList,
  };
}

export default CustomerService();
