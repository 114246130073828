/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import Barcode from 'react-barcode';
import { useParams } from 'react-router-dom'; // Import useParams
import { useReactToPrint } from 'react-to-print';
import iconCoverSuper from '../../assets/img/coversuper_icon.png';
import iconKiriminAja from '../../assets/img/kiriminaja.png';
import ShippingManagementServices from '../../services/ShippingManagementServices';

const ShipmentLabel = (props) => {
  const { order_id } = useParams(); // Mengambil order_id dari URL
  const labelRef = useRef();

  const [data, setData] = useState({
    id: order_id,
    awb: '',
    insurance_fee: 0,
    items: [{ product: { name: '' }, qty: 1 }],
    description: '',
    shipment: {
      shipper: {
        name: 'PT CoverSuper Indonesia Global',
        address: '',
        subdistrict: { name: '' },
        district: { name: '', type: '' },
        province: { name: '' },
        phone: '',
      },
      total_weight: 0,
      courier_logo: '',
      courier_code: '',
      service_code: '',
      service_name: '',
      tracking_number: '',
      name: '',
      address: '',
      subdistrict: { name: '' },
      district: { name: '', type: '' },
      province: { name: '' },
      phone: '',
      postal_code: '',
    },
  });
  useEffect(() => {
    if (order_id) getData(order_id);
  }, [order_id]);

  const getData = async (orderId) => {
    const res = await ShippingManagementServices.shippingDetail(orderId);
    console.log({ data: res.data });
    setData(res.data);
    getCanvas();
  };

  const getCanvas = async () => {
    setTimeout(() => {
      handlePrint();
    }, 1000);
  };

  const handlePrint = useReactToPrint({
    content: () => labelRef.current,
    documentTitle: 'Print Label',
  });

  return (
    <div
      className="p-2"
      ref={labelRef}
      style={{
        width: '500px',
        height: '550px',
      }}
    >
      <div id="print-label-component">
        <div>
          <Row className=" border p-1">
            <Col
              className="p-2 justify-content-between align-items-center"
              span={8}
              style={{ textAlign: 'left' }}
            >
              <div>
                <img width={100} src={iconKiriminAja} alt="..." />
              </div>
            </Col>
            <Col
              className="p-2 justify-content-between align-items-center"
              span={8}
              style={{ textAlign: 'center' }}
            >
              <div>
                <img width={100} src={iconCoverSuper} alt="..." />
              </div>
            </Col>
            <Col className="p-2" span={8} style={{ textAlign: 'right' }}>
              <div>
                <h5 className="text-base font-bold">NON-COD</h5>
              </div>
            </Col>
          </Row>
          <Row className="border p-1">
            <Col span={8} className="p-1 d-flex flex-column align-items-center">
              <img className="border" src={data.shipment.courier_logo} alt="Courier Logo" />
            </Col>
            <Col span={8} className="p-1 d-flex flex-column align-items-center">
              <p className="text-xs">Layanan</p>
              <h5 className="text-sm font-bold">{data.shipment.service_name}</h5>
            </Col>
            <Col span={8} className="p-1 d-flex justify-content-between align-items-center">
              <p className="text-xs">No Trx KiriminAja</p>
              <p className="text-sm font-bold">CSIG-{data.id}</p>
            </Col>
          </Row>

          <Row justify="center" className="border p-1">
            <Col span={24}>
              <div className="d-flex justify-content-between align-items-center">
                <Barcode
                  className="w-full max-w-full"
                  format="CODE128"
                  renderer="img"
                  value={data.awb}
                  height={30}
                  width={2}
                  fontSize={15}
                  textAlign="center"
                  margin={10}
                />
              </div>
            </Col>
          </Row>

          <Row className="border p-1">
            <Col span={8} className="p-1">
              <p className="text-xs mx-2">Asuransi</p>
              <p className="text-sm font-semibold mx-2">
                Rp {new Intl.NumberFormat('id-ID').format(data.insurance_fee)},-
              </p>
            </Col>
            <Col span={8} className="p-1">
              <p className="text-xs mx-2">Berat</p>
              <p className="text-sm font-semibold mx-2">
                {new Intl.NumberFormat('id-ID').format(data.shipment.total_weight)}gr
              </p>
            </Col>
            <Col span={8} className="p-1">
              <p className="text-xs mx-2">Quantity</p>
              <p className="text-sm font-semibold mx-2">
                {data.items.reduce((total, item) => total + item.qty, 0)} Pcs
              </p>
            </Col>
          </Row>

          <Row className="border p-1">
            <Col span={12} className="px-2">
              <p className="text-[11px]">Penerima</p>
              <p className="text-xs font-semibold">{data.shipment.name}</p>
              <p className="text-[11px] mt-2">
                {data.shipment.address}, {data.shipment.subdistrict.name},{' '}
                {data.shipment.district.type === 'Kabupaten' ? 'Kab' : 'Kota'}{' '}
                {data.shipment.district.name}, {data.shipment.province.name},{' '}
                {data.shipment.postal_code}
              </p>
              <p className="text-xs font-semibold mt-1">{data.shipment.phone}</p>
            </Col>

            <Col span={12} className="px-2">
              <p className="text-[11px]">Dari</p>
              <p className="text-xs font-semibold">{data.shipment.shipper.name}</p>
              <p className="text-[11px] mt-2">
                {data.shipment.shipper.address}, {data.shipment.shipper.subdistrict.name},{' '}
                {data.shipment.shipper.district.type === 'Kabupaten' ? 'Kab' : 'Kota'}{' '}
                {data.shipment.shipper.district.name}, {data.shipment.shipper.province.name},{' '}
                {data.shipment.shipper.postal_code}
              </p>
              <p className="text-xs font-semibold mt-1">{data.shipment.shipper.phone}</p>
            </Col>
          </Row>

          <Row className="border p-1">
            <Col span={24} className="px-2">
              <p className="text-[10px]">Isi Paket:</p>
              <p className="text-xs font-semibold">{data.items.map((item) => item.product.name)}</p>
            </Col>
          </Row>
          <Row className="border p-1">
            <Col span={24} className="px-2">
              <p className="text-[10px]">Catatan:</p>
              <p className="text-xs">-</p>
            </Col>
          </Row>

          <Row className="border p-1">
            <Col span={24}>
              <p className="text-[11px] font-semibold italic mb-0">
                * Pengirim wajib meminta bukti serah terima paket ke kurir.
              </p>
              <p className="text-[11px] font-semibold italic mb-0">
                * Jika paket ini retur, pengirim tetap dikenakan biaya keberangkatan dan biaya retur
                sesuai ekspedisi.
              </p>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default ShipmentLabel;
