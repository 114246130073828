/* eslint-disable import/no-anonymous-default-export */
import CryptoJS from 'crypto-js';
import 'moment/locale/id';
import {
  AUTH_ERROR,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  USER_LOADED,
  USER_LOADING,
} from './type';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: localStorage.getItem('isAuthenticated') === 'true',
  isLoading: false,
  user: JSON.parse(localStorage.getItem('user')),
  permissions: localStorage.getItem('permissions')
    ? JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem('permissions'),
          'CoverSuperIndonesiaGlobalV2'
        ).toString(CryptoJS.enc.Utf8)
      )
    : [],
};

export default function (state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case USER_LOADING:
      return { ...state, isLoading: true };
    case USER_LOADED:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        user: payload,
      };

    case LOGIN_SUCCESS:
      localStorage.setItem('token', payload.meta.token);
      localStorage.setItem('refreshToken', payload.meta.refreshToken);
      localStorage.setItem('expires_at', payload.meta.expires_at);
      localStorage.setItem('isAuthenticated', true);
      localStorage.setItem('user', JSON.stringify(payload.data));
      localStorage.setItem('role', payload.data.role.id);
      localStorage.setItem(
        'permissions',
        CryptoJS.AES.encrypt(
          JSON.stringify(payload.meta.permissions),
          'CoverSuperIndonesiaGlobalV2'
        )
      );

      // const expiresIn = () => {
      //   let x = moment();
      //   let y = moment(payload.meta.expires_at);
      //   let duration = moment.duration(y.diff(x));
      //   // returns duration object with the duration between x and y
      //   return duration.as('ms');
      // };

      // setTimeout(function () {
      //   localStorage.clear();
      //   Swal.fire({
      //     timer: 3000,
      //     icon: 'info',
      //     title: 'Oops...',
      //     text: 'Sesi telah habis, silahkan login kembali!',
      //     willClose: () => {
      //       window.location.href = '/auth/login';
      //     },
      //   });
      // }, expiresIn());

      return {
        ...state,
        token: payload.meta.token,
        refreshToken: payload.meta.refreshToken,
        expires_at: payload.meta.expires_at,
        isAuthenticated: true,
        isLoading: false,
        user: payload.data,
        permissions: payload.meta.permissions,
      };

    case LOGIN_FAIL:
    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
      localStorage.clear();
      return {
        ...state,
        permissions: [],
        token: null,
        refreshToken: null,
        expires_at: null,
        user: null,
        isLoading: false,
        isAuthenticated: false,
      };
    default:
      return state;
  }
}
