import { Button, Card, Form, Input } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Graphic from '../../../assets/img/graphic.png';
import Logo from '../../../assets/img/logo_putih.png';
import { FLOW_VERSION } from '../../../constants';
import { login } from '../../../store/Auth/action';
import { resetCustomer, resetRegional } from '../../../store/FormOrder/Customer/action';
import { resetProduct } from '../../../store/FormOrder/Products/action';
import { resetDistricts, resetSubdistricts } from '../../../store/FormOrder/Regional/action';
import { resetDataSummary } from '../../../store/FormOrder/Summary/action';

const Login = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.isLoading) || false;

  // const [error, setError] = useState(false);

  const onFinish = (values) => {
    const { email, password } = values;
    dispatch(login(email, password));
    dispatch(resetProduct());
    dispatch(resetCustomer());
    dispatch(resetDataSummary());
    dispatch(resetRegional());
    dispatch(resetDistricts());
    dispatch(resetSubdistricts());
  };

  return (
    <div className="relative h-screen w-screen bg-gradient-to-b from-[#000000] to-[#16222A] flex justify-center items-center">
      <div className="absolute left-0 bottom-0"></div>
      <img className="absolute right-0 bottom-0" src={Graphic} alt="" width={90} />

      <Card className="w-96 h-96 border-0 bg-transparent text-gray-300 antialiased">
        <div className="text-center">
          <img className="inline-block" src={Logo} alt="" width={110} />
          <h2 className="text-base my-10">Welcome to Coversuper Dashboard</h2>
        </div>

        <Form
          form={form}
          onFinish={onFinish}
          // onValuesChange={() => setError(false)}
          layout="vertical"
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Email atau Username" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>

          <Form.Item>
            <Button
              loading={loading}
              htmlType="submit"
              className="bg-red-700 text-white border-0 w-full"
            >
              Login
            </Button>
          </Form.Item>
        </Form>
        <h2 className="text-center text-white text-xs my-10">Versi {FLOW_VERSION}</h2>
      </Card>
    </div>
  );
};

export default Login;
