import CustomerServices from '../../../services/CustomerServices';
import {
  CREATE_INVOICE,
  CUSTOMER_LOADED,
  CUSTOMER_LOADING,
  GET_CUSTOMER_BY_ID_SUCCESS,
  REQUEST_TAX_INVOICE,
  RESET_CUSTOMER,
  RESET_REGIONAL_CUSTOMER,
  SELECTED_ORDER_DATE,
  SELECTED_ORDER_SOURCE,
  SELECTED_PLATFORM,
  UPDATE_ADDRESS,
  UPDATE_CUSTOMER_DISTRICT_ID,
  UPDATE_CUSTOMER_PROVINCE_ID,
  UPDATE_CUSTOMER_SUBDISTRICT_ID,
  UPDATE_EMAIL_CUSTOMER,
  UPDATE_NAME_CUSTOMER,
  UPDATE_NUMBER_CUSTOMER,
  UPDATE_POSTAL_CODE,
  UPDATE_PREFIX_PHONE,
  UPLOAD_NPWP,
} from './type';

export const findCustomerById = (value) => async (dispatch) => {
  try {
    dispatch({ type: CUSTOMER_LOADING });
    const response = await CustomerServices.findCustomerById(value);

    if (response.status === 200) {
      dispatch({
        type: GET_CUSTOMER_BY_ID_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    console.log(error);
  } finally {
    dispatch({ type: CUSTOMER_LOADED });
  }
};

export const updateName = (value) => (dispatch) => {
  dispatch({
    type: UPDATE_NAME_CUSTOMER,
    payload: value,
  });
};

export const updateEmail = (value) => (dispatch) => {
  dispatch({
    type: UPDATE_EMAIL_CUSTOMER,
    payload: value,
  });
};

export const updatePhone = (value) => (dispatch) => {
  dispatch({
    type: UPDATE_NUMBER_CUSTOMER,
    payload: value,
  });
};

export const updatePrefix = (value) => (dispatch) => {
  dispatch({
    type: UPDATE_PREFIX_PHONE,
    payload: value,
  });
};

export const updateProvinceId = (value, name) => (dispatch) => {
  dispatch({
    type: UPDATE_CUSTOMER_PROVINCE_ID,
    payload: {
      value: value,
      name: name,
    },
  });
};

export const updateDistrictId = (value, name) => (dispatch) => {
  dispatch({
    type: UPDATE_CUSTOMER_DISTRICT_ID,
    payload: {
      value: value,
      name: name,
    },
  });
};

export const updateSubdistrictId = (value, name) => (dispatch) => {
  dispatch({
    type: UPDATE_CUSTOMER_SUBDISTRICT_ID,
    payload: {
      value: value,
      name: name,
    },
  });
};

export const resetRegional = () => (dispatch) => {
  dispatch({
    type: RESET_REGIONAL_CUSTOMER,
  });
};

export const updatePostalCode = (value) => (dispatch) => {
  dispatch({
    type: UPDATE_POSTAL_CODE,
    payload: value,
  });
};

export const updateAddress = (value) => (dispatch) => {
  dispatch({
    type: UPDATE_ADDRESS,
    payload: value,
  });
};

export const orderSource = (value) => async (dispatch) => {
  dispatch({
    type: SELECTED_ORDER_SOURCE,
    payload: value,
  });
};

export const selectPlatform = (value, invoice_required) => async (dispatch) => {
  dispatch({
    type: SELECTED_PLATFORM,
    payload: {
      platform: value,
      invoice_required: invoice_required,
    },
  });
};

export const requestTaxInvoice = (value) => async (dispatch) => {
  dispatch({
    type: REQUEST_TAX_INVOICE,
    payload: value,
  });
};

export const uploadNpwp = (url) => async (dispatch) => {
  dispatch({
    type: UPLOAD_NPWP,
    payload: url,
  });
};

export const createInvoice = (value) => async (dispatch) => {
  dispatch({
    type: CREATE_INVOICE,
    payload: value,
  });
};

export const selectOrderDate = (value) => async (dispatch) => {
  dispatch({
    type: SELECTED_ORDER_DATE,
    payload: value,
  });
};
export const resetCustomer = () => async (dispatch) => {
  dispatch({
    type: RESET_CUSTOMER,
  });
};
