import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../../store/Auth/action';

const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    console.log('logout');
    dispatch(logout());
  }, [dispatch]);

  return <></>;
};

export default Logout;
