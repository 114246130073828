import {
  COURIER_COST,
  COURIER_SERVICE,
  DATA_COURIER,
  GET_COURIER_SUCCESS,
  GET_SHIPPING_COST_SUCCESS,
  ORIGIN_ID,
  RESET_COST_SERVICE,
  RESET_COURIER,
  RESET_DATA_SHIPPING_COST,
  TRACKING_NUMBER,
  UPDATE_COURIER,
} from './type';

const initialState = {
  couriers: [],
  shippingCost: [],
  origin_id: null,
  courier_name: '',
  courier_code: '',
  courier_service: '',
  service_name: '',
  shipping_cost: 0,
  tracking_number: '',
};

const courierReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COURIER_SUCCESS:
      return { ...state, couriers: action.payload };
    case TRACKING_NUMBER:
      return { ...state, tracking_number: action.payload };
    case GET_SHIPPING_COST_SUCCESS:
      return { ...state, shippingCost: action.payload };
    case DATA_COURIER:
      return {
        ...state,
        courier_code: action.payload.value,
        courier_name: action.payload.label,
      };
    case COURIER_COST:
      return {
        ...state,
        shipping_cost: action.payload,
      };
    case COURIER_SERVICE:
      console.log(action.payload);
      return {
        ...state,
        courier_service: action.payload.service,
        service_name: action.payload.description,
      };
    case UPDATE_COURIER:
      return { ...state, couriers: [...action.payload, ...state.couriers] };
    case ORIGIN_ID:
      return { ...state, origin_id: action.payload };
    case RESET_DATA_SHIPPING_COST:
      return { ...state, shippingCost: [] };
    case RESET_COURIER:
      return {
        ...state,
        courier_code: '',
        courier_name: '',
      };
    case RESET_COST_SERVICE:
      return {
        ...state,
        courier_service: '',
        shipping_cost: 0,
      };
    default:
      return state;
  }
};

export default courierReducer;
