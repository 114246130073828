import {
  ADDITIONAL_COST,
  ADDITIONAL_COST_NOTE,
  BONUS,
  DESCRIPTION,
  DISCOUNT,
  GET_BONUS_OPTION_SUCCESS,
  RESET_SUMMARY,
} from './type'

const initialState = {
  discount: 0,
  additional_cost: 0,
  additional_cost_note: '',
  bonusOptions: [],
  bonus: [],
  description: '',
}

const summaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case DISCOUNT:
      return { ...state, discount: action.payload }
    case ADDITIONAL_COST:
      return { ...state, additional_cost: action.payload }
    case ADDITIONAL_COST_NOTE:
      return { ...state, additional_cost_note: action.payload }
    case GET_BONUS_OPTION_SUCCESS:
      return { ...state, bonusOptions: action.payload }
    case BONUS:
      return { ...state, bonus: action.payload }
    case DESCRIPTION:
      return { ...state, description: action.payload }
    case RESET_SUMMARY:
      return {
        discount: 0,
        additional_cost: 0,
        additional_cost_note: '',
        bonusOptions: [],
        bonus: [],
        description: '',
      }
    default:
      return state
  }
}

export default summaryReducer
