import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import rootReducer from "./rootReducers";

const persistConfig = {
  key: "auth-persist",
  storage,
  whitelist: ["auth", "customer", "products", "regional"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

const Persistor = persistStore(store);

export { Persistor };
export default store;
