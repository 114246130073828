// src/components/FullScreenLoading.js
import { Spin } from 'antd';
import React from 'react';

const Loading = () => {
  return (
    <div className="fixed inset-0 bg-gray-100 bg-opacity-50 flex items-center justify-center z-50">
      <Spin size="large" />
    </div>
  );
};

export default Loading;
