import { Menu } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import ability from '../../ability/ability';
import { routeList } from '../../routes/routeList';

const SideNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [listMenu, setListMenu] = useState([]);
  const [menuOpen, setMenuOpen] = useState([]);

  const listRoute = routeList().find((o) => o.path === '/');

  useEffect(() => {
    const dataListMenu = _.filter(listRoute.children, function (value) {
      return value.path !== 'root';
    });
    dataListMenu.forEach((item, index) => {
      item.index = index;
    });
    setListMenu(dataListMenu);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const currentLocation = location.pathname;
    listMenu.forEach((item) => {
      item.children?.forEach((i) => {
        if (i.key === currentLocation) {
          setMenuOpen([item.index.toString()]);
        }
      });
    });
  }, [listMenu]); // eslint-disable-line react-hooks/exhaustive-deps

  // New Menu mengikuti Ant Design versi terbaru
  // dengan menambahkan permission @casl/ability
  const sidebarWithPermission = () => {
    const testing = listMenu.filter(function (item) {
      let childrens = item.children;
      if (childrens) {
        let filteredchildren = childrens.filter(function (val) {
          let innerChildren = val.children;
          if (innerChildren) {
            let filterInner = innerChildren.filter((tes) => ability.can(tes.actions, tes.subject));
            val.children = filterInner;
          }
          return ability.can(val.actions, val.subject);
        });
        item.children = filteredchildren;
      }

      return ability.can(item.actions, item.subject);
    });
    return testing;
  };

  return (
    <>
      <Menu
        className="antialiased bg-transparent text-gray-400 font-normal text-sm/5"
        mode="inline"
        onClick={({ key }) => {
          if (key) {
            navigate(key);
          }
        }}
        items={sidebarWithPermission()}
        defaultSelectedKeys={[location.pathname]}
        defaultOpenKeys={menuOpen}
      />
    </>
  );
};

export default SideNavbar;
