export const SET_USER_DATA = "SET_USER_DATA";

export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
// AUTH
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const AUTH_ERROR = "AUTH_ERROR";
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const SET_PERMISSIONS = "SET_PERMISSIONS";
