import { SOCKET_CONNECTED, SOCKET_DISCONNECTED } from './types';

// check token and load user
export const loadSocket = (socket) => (dispatch) => {
  dispatch({ type: SOCKET_CONNECTED, payload: { socket } });
};

// disconnect socket
export const disconnectSocket = () => (dispatch) => {
  dispatch({ type: SOCKET_DISCONNECTED });
};
