import { SOCKET_CONNECTED, SOCKET_DISCONNECTED } from './types';

const initialState = {
  io: {
    connected: false,
    id: null,
  },
};

export default function socketReducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case SOCKET_CONNECTED:
      console.log('socket connected');
      return { ...state, io: payload.socket };
    case SOCKET_DISCONNECTED:
      console.log('socket disconnected');
      return initialState;
    default:
      return state;
  }
}
