import {
  GET_PAYMENT_CHANNEL_SUCCESS,
  GET_STATUS_PAYMENT_SUCCESS,
  PAYMENT_AMOUNT,
  PAYMENT_CHANNEL,
  PAYMENT_DATE,
  STATUS_PAYMENT_ID,
} from "./type";

const initialState = {
  paymentStatusOptions: [],
  paymentChannelOptions: [],
  payment_status_id: null,
  payment_channel_id: null,
  verification_type: null,
  payment_date: null,
  payment_amount: 0,
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STATUS_PAYMENT_SUCCESS:
      return { ...state, paymentStatusOptions: action.payload };
    case GET_PAYMENT_CHANNEL_SUCCESS:
      return { ...state, paymentChannelOptions: action.payload };
    case STATUS_PAYMENT_ID:
      return { ...state, payment_status_id: action.payload };
    case PAYMENT_CHANNEL:
      return {
        ...state,
        payment_channel_id: action.payload.payment_channel_id,
        verification_type: action.payload.verification_type,
      };
    case PAYMENT_DATE:
      return { ...state, payment_date: action.payload };
    case PAYMENT_AMOUNT:
      return { ...state, payment_amount: action.payload };
    default:
      return state;
  }
};

export default paymentReducer;
